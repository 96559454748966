import React, { Fragment, useState } from 'react';
import { Link } from 'gatsby';

import routes from 'src/data/routes';
import CrossIcon from 'src/assets/icons/cross.svg';

function NavigationButtons({ name, link, subLinks, toggle }) {
  return (
    <>
      {!subLinks ? (
        <h5>
          <Link
            to={`/${link}`}
            className="block px-4 py-3 border-light-grey border-b-2"
          >
            {name}
          </Link>
        </h5>
      ) : (
        <h5>
          <span className="block px-4 py-3 pb-1">{name}</span>
        </h5>
      )}
      {subLinks && (
        <ul className="pb-2 border-b-2 border-light-grey">
          {subLinks.map(({ name: subName, link: subLink }, subIndex) => (
            <li key={subIndex} onClick={toggle}>
              <Link to={`/${link}/${subLink}`} className="block px-4 py-1">
                {subName}
              </Link>
            </li>
          ))}
        </ul>
      )}
    </>
  );
}

const MobileNavigation = () => {
  const [toggle, setToggle] = useState(false);

  const toggleMobileNavigation = () => {
    const body = document.body;

    body.classList.toggle('overflow-hidden');
    setToggle(!toggle);
  };
  return (
    <div className="lg:hidden" aria-hidden="true">
      {/* Overlay */}
      <div
        className={`fixed z-30 top-0 bottom-0 left-0 right-0 bg-dark-blue transition-all duration-300 ${
          toggle ? 'visible opacity-75' : 'invisible opacity-0'
        }`}
        onClick={toggleMobileNavigation}
        aria-hidden="true"
      />
      {/* Menu Button */}
      <button
        type="button"
        className="flex items-center h-full text-dark-blue pr-3 md:pl-3"
        onClick={toggleMobileNavigation}
      >
        <span className="burger-icon" />
      </button>
      {/* Mobile Navigation */}
      <aside
        className={`fixed z-40 top-0 bottom-0 right-0 w-full max-w-300px bg-white overflow-auto transform transition-all duration-300 ${
          toggle ? 'visible translate-x-0' : 'invisible translate-x-full'
        }`}
      >
        <nav>
          <button
            type="button"
            className="block px-4 py-3 ml-auto"
            onClick={toggleMobileNavigation}
          >
            <CrossIcon className="w-10 h-10 fill-current" />
          </button>
          <ul className="border-t-2 border-light-grey">
            {routes.map(({ name, link, subLinks }, index) => {
              const isMailtToLink = link.includes('mailto:');
              return (
                <Fragment key={index}>
                  <li>
                    {isMailtToLink ? (
                      <h5>
                        <a
                          href={`${link}?subject=${name}`}
                          className={`block px-4 py-3 border-light-grey border-b-2`}
                        >
                          {name}
                        </a>
                      </h5>
                    ) : (
                      <NavigationButtons
                        name={name}
                        link={link}
                        subLinks={subLinks}
                        toggle={toggleMobileNavigation}
                      />
                    )}
                  </li>
                </Fragment>
              );
            })}
          </ul>
        </nav>
      </aside>
    </div>
  );
};

export default MobileNavigation;
