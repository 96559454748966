import React from 'react';

import FacebookIcon from 'src/assets/icons/social/facebook.svg';
import LinkedInIcon from 'src/assets/icons/social/linkedin.svg';
import TwitterIcon from 'src/assets/icons/social/twitter.svg';
import XingIcon from 'src/assets/icons/social/xing.svg';
import YouTubeIcon from 'src/assets/icons/social/youtube.svg';

const shareLinks = [
  {
    href:
      'https://www.facebook.com/sharer/sharer.php?u=https://www.nuernberger.de/',
    icon: FacebookIcon,
    text: 'Seite auf Facebook teilen'
  },
  {
    href:
      'https://www.linkedin.com/shareArticle?mini=true&url=https://www.nuernberger.de/',
    icon: LinkedInIcon,
    text: 'Seite auf LinkedIn teilen'
  },
  {
    href:
      'https://www.xing.com/app/user?op=share;url=https://www.nuernberger.de/',
    icon: XingIcon,
    text: 'Seite auf Xing teilen'
  },
  {
    href: 'https://twitter.com/home?status=https://www.nuernberger.de/',
    icon: TwitterIcon,
    text: 'Seite auf Twitter teilen'
  }
];

const socialLinks = [
  {
    href: 'https://www.facebook.com/nuernberger',
    icon: FacebookIcon,
    text: 'Facebook'
  },
  {
    href: 'https://www.xing.com/company/nuernberger',
    icon: XingIcon,
    text: 'Xing'
  },
  {
    href: 'https://twitter.com/nuernberger',
    icon: TwitterIcon,
    text: 'Twitter'
  },
  {
    href: 'https://www.youtube.com/user/NuernbergerTV',
    icon: YouTubeIcon,
    text: 'YouTube'
  }
];

const SocialMedia = () => {
  const linkClasses =
    'inline-flex justify-center items-center w-10 h-10 bg-dark-blue text-white rounded-full mr-2 last:mr-0 transition-colors duration-300 hover:bg-gray-blue';
  const iconClasses = 'w-5 h-5 fill-current';

  return (
    <div className="mt-8 md:flex md:justify-between">
      <div className="mb-4 md:mb-0">
        <p className="mb-2">Empfehlen Sie uns weiter</p>
        <div className="flex">
          {shareLinks.map((link, index) => (
            <a
              key={index}
              href={link.href}
              title={link.text}
              className={linkClasses}
            >
              <link.icon className={iconClasses} aria-hidden="true" />
              <span className="sr-only">{link.text}</span>
            </a>
          ))}
        </div>
      </div>
      <div>
        <p className="mb-2 md:text-right">Besuchen Sie uns auf</p>
        <nav className="flex">
          {socialLinks.map((link, index) => (
            <a key={index} href={link.href} className={linkClasses}>
              <link.icon className={iconClasses} aria-hidden="true" />
              <span className="sr-only">{link.text}</span>
            </a>
          ))}
        </nav>
      </div>
    </div>
  );
};

export default SocialMedia;
