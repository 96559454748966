import React from 'react';
import { Link } from 'gatsby';

import Breadcrumb from 'src/layout/Breadcrumb';
import SocialMedia from 'src/layout/SocialMedia';

import routes from 'src/data/routes';

const Footer = () => (
  <footer className="container container--simple mt-10 pb-10">
    <Breadcrumb />
    {/* Social */}
    <SocialMedia />
    {/* Navigation */}
    <div className="link-underline-hovers pt-4 pb-8 border-t-2 border-b-2 border-light-grey my-8">
      <nav className="leading-relaxed -mx-4 sm:flex sm:flex-row sm:flex-wrap">
        {routes.map(({ name, link, subLinks }, index) => (
          <ul key={index} className="px-4 mt-4 sm:w-1/2 lg:w-auto lg:flex-1">
            <li>
              <h5 className={`text-lg ${subLinks ? 'mb-2' : undefined}`}>
                {subLinks ? name : <Link to={`/${link}`}>{name}</Link>}
              </h5>
              {subLinks && (
                <ul>
                  {subLinks.map(
                    ({ name: secondName, link: secondLink }, index) => (
                      <li key={index}>
                        <Link
                          to={`/${link}/${secondLink}`}
                          className="inline-block"
                        >
                          {secondName}
                        </Link>
                      </li>
                    )
                  )}
                </ul>
              )}
            </li>
          </ul>
        ))}
      </nav>
    </div>
    {/* Meta */}
    <nav className="link-underline-hovers">
      <a href="https://www.nuernberger.de/datenschutz/" rel="noopener nofollow noreferrer">
        Datenschutz
      </a>
      <span className="border-r-2 border-light-grey mx-3"></span>
      <a href="/impressum/" rel="noopener nofollow noreferrer">
        Impressum
      </a>
    </nav>
  </footer>
);

export default Footer;
