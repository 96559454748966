const replaceUmlaute = input =>
  input
    .replace(/%C3%84/g, 'Ä')
    .replace(/%C3%96/g, 'Ö')
    .replace(/%C3%9C/g, 'Ü')
    .replace(/%C3%9F/g, 'ß')
    .replace(/%C3%A4/g, 'ä')
    .replace(/%C3%B6/g, 'ö')
    .replace(/%C3%BC/g, 'ü')
    .replace(/%E1%BA%9E/g, 'ß')
    .replace(/%20/g, ' ')
    .replace(/%E2%80%8B/g, '');

export { replaceUmlaute };
