import React from 'react';

const SearchResult = ({ name, link, snippet }) => {
  return (
    <a
      href={link}
      className="lg:p-8 md:p-4 hover:bg-dark-blue hover:text-white block"
    >
      <h5>{name}</h5>
      <p className="lg:mt-4 md:mt-2">{snippet}</p>
    </a>
  );
};

export default SearchResult;
