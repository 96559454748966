import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';

import routes from 'src/data/routes';

const Subnavigation = ({ link, subLinks }) => {
  const data = useStaticQuery(graphql`
    {
      blogPosts: allWordpressPost(
        limit: 3
        sort: { fields: date, order: DESC }
      ) {
        edges {
          node {
            slug
            title
          }
        }
      }
    }
  `);

  return (
    <div className="sub-nav link-underline-hovers invisible opacity-0 flex flex-row absolute z-30 top-full left-0 right-0 bg-white py-8 border-t-4 border-dark-blue shadow-md transition-all duration-300">
      {subLinks.map(
        (
          {
            name: subName,
            link: subLink,
            icon: Icon,
            subLinks: secondSublinks,
            showBlogPosts
          },
          index
        ) => (
          <ul
            key={index}
            className={`flex flex-col flex-1 px-8 ${
              index + 1 < subLinks.length ? 'border-r-2 border-light-grey' : ''
            }`}
          >
            <li className="mb-6">
              <Link
                to={`/${link}/${subLink}`}
                className="flex items-center font-bold text-dark-turquoise"
              >
                {Icon && <Icon className="w-10 h-10 mr-4 fill-current"></Icon>}
                {subName}
              </Link>
            </li>
            {secondSublinks &&
              secondSublinks.map(
                ({ name: secondSubName, link: secondSubLink }, subIndex) => (
                  <li key={subIndex}>
                    <Link
                      to={`/${link}/${subLink}/${secondSubLink}`}
                      className="block py-1"
                    >
                      {secondSubName}
                    </Link>
                  </li>
                )
              )}
            {showBlogPosts &&
              data.blogPosts.edges.map((blogPost, blogPostIndex) => {
                if (blogPost.node.title === 'Nicht löschen') return '';
                return (
                  <li key={blogPostIndex}>
                    <Link
                      to={`/aktuelles/newsroom/${blogPost.node.slug}`}
                      className="block py-1"
                    >
                      {blogPost.node.title}
                    </Link>
                  </li>
                );
              })}
          </ul>
        )
      )}
    </div>
  );
};

const Navigation = () => {
  const navItemClasses =
    'parent flex items-center h-full font-bold text-center px-4 transition-colors duration-300 hover:bg-dark-blue hover:text-white xl:text-xl';
  return (
    <nav className="hidden lg:block">
      <ul className="flex h-full">
        {routes.map(({ name, link, subLinks }, index) => (
          <li key={index} className={subLinks ? 'show-sub-nav' : undefined}>
            {link.includes('mailto:') ? (
              <a href={`${link}?subject=${name}`} className={navItemClasses}>
                {name}
              </a>
            ) : (
              <>
                {!subLinks ? (
                  <Link to={`/${link}`} className={navItemClasses}>
                    {name}
                  </Link>
                ) : (
                  <>
                    <span className={navItemClasses}>{name}</span>
                    <Subnavigation link={link} subLinks={subLinks} />
                  </>
                )}
              </>
            )}
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Navigation;
