import { globalHistory } from '@reach/router';
import { replaceUmlaute } from './replace-umlaute';

const getPathname = () => {
  const pathname = globalHistory.location.pathname;
  return replaceUmlaute(pathname);
};

const getParentPathname = () => {
  const pathname = globalHistory.location.pathname.slice(0, -1);
  const parentPathname = pathname.substring(0, pathname.lastIndexOf('/'));

  return parentPathname;
};

export { getPathname, getParentPathname };
