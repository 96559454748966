import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import { getPathname, getParentPathname } from 'src/utils/get-pathname';

const SubpageNavigation = ({ navItems, activeNavItem, useParentPathname }) => {
  const [pathname, setPathname] = React.useState(null);
  React.useEffect(() => {
    const pathname = useParentPathname ? getParentPathname() : getPathname();
    if (pathname.charAt(pathname.length - 1) === '/') {
      setPathname(pathname.substring(0, pathname.length - 1));
    } else {
      setPathname(pathname);
    }
  }, [setPathname, useParentPathname]);

  return (
    <nav className="relative z-20 max-w-screen-lg bg-white mx-auto shadow-md md:flex lg:transform lg:-translate-y-1/2 lg:-mb-20">
      {navItems.map(({ href, icon, name }, index) => (
        <Link
          key={index}
          to={`${pathname}${href}`}
          className={`flex items-center p-4 transition-colors duration-300 hover:bg-light-blue md:block md:flex-1 md:text-center md:whitespace-pre md:py-6 ${
            activeNavItem === index + 1 ? 'text-turquoise' : 'text-dark-blue'
          }`}
        >
          {icon && (
            <svg
              className="flex-none w-10 h-10 mr-3 fill-current md:w-12 md:h-12 md:mx-auto md:mb-3"
              aria-hidden="true"
              {...icon.defaultProps}
            >
              {icon().props.children}
            </svg>
          )}
          {name}
        </Link>
      ))}
    </nav>
  );
};

SubpageNavigation.propTypes = {
  navItems: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string.isRequired,
      icon: PropTypes.func,
      name: PropTypes.string.isRequired
    })
  ).isRequired,
  activeNavItem: PropTypes.number,
  useParentPathname: PropTypes.bool
};

export default SubpageNavigation;
