import NewspaperIcon from 'src/assets/icons/newspaper.svg';
import BookOpenIcon from 'src/assets/icons/book-open.svg';
import OpenTextIcon from 'src/assets/icons/open-text.svg';

import FileContractIcon from 'src/assets/icons/file-contract.svg';
import HandShakeIcon from 'src/assets/icons/handshake.svg';
import UserCheckIcon from 'src/assets/icons/user-check.svg';

import UserTieIcon from 'src/assets/icons/user-tie.svg';
import UsersIcon from 'src/assets/icons/users.svg';
import BuildingIcon from 'src/assets/icons/building.svg';

const routes = [
  {
    name: 'Wir über uns',
    link: 'wir-ueber-uns'
  },
  {
    name: 'Aktuelles',
    link: 'aktuelles',
    subLinks: [
      {
        name: 'Newsroom',
        link: 'newsroom',
        icon: NewspaperIcon,
        showBlogPosts: true
      },
      {
        name: 'Ratgeber',
        link: 'ratgeber',
        icon: BookOpenIcon,
        subLinks: [
          {
            name: 'Arbeitgeberfinanzierte Altersvorsorge',
            link: 'arbeitgeberfinanzierte-altersvorsorge'
          },
          {
            name: 'Rentenversicherung für Geschäftsführer',
            link: 'betriebliche-altersvorsorge'
          },
          {
            name: 'Berufsunfähigkeitsschutz clever nutzen',
            link: 'berufsunfaehigkeit'
          }
        ]
      },
      {
        name: 'Newsletter',
        link: 'newsletter',
        icon: OpenTextIcon,
        subLinks: [
          {
            name: 'Newsletter Archiv',
            link: 'archiv'
          }
        ]
      }
    ]
  },
  {
    name: 'Lösungen',
    link: 'unsere-loesungen',
    subLinks: [
      {
        name: 'Absicherung für leitende Angestellte',
        link: 'absicherung-fuer-leitende-angestellte',
        icon: UserTieIcon,
        subLinks: [
          {
            name: 'Entgeltumwandlung',
            link: 'entgeltumwandlung-laufende-beitraege'
          },
          {
            name: 'Einmalbeiträge',
            link: 'entgeltumwandlung-laufende-beitraege#einmalbeitraege'
          },
          {
            name: 'Leistungszusage',
            link: 'entgeltumwandlung-laufende-beitraege#leistungszusage'
          }
        ]
      },
      {
        name: 'Absicherung für Mitarbeiter',
        link: 'absicherung-fuer-mitarbeiter',
        icon: UsersIcon,
        subLinks: [
          {
            name: 'Betriebliche Altersvorsorge (bAV)',
            link: 'betriebliche-altersvorsorge',
            subLinks: [
              {
                name: 'bAV Direktversicherung',
                link: 'bav-direktversicherung'
              },
              {
                name: 'bAV Pensionsfonds',
                link: 'bav-pensionsfonds'
              },
              {
                name: 'bAV Pensionszusage',
                link: 'bav-pensionszusage'
              },
              {
                name: 'bAV Unterstützungskasse',
                link: 'bav-unterstuetzungskasse'
              }
            ]
          },
          {
            name: 'Betrieblicher Einkommensschutz (EKS)',
            link: 'betrieblicher-einkommensschutz',
            subLinks: [
              {
                name: 'EKS Berufsunfähigkeit',
                link: 'eks-berufsunfaehigkeit'
              },
              {
                name: 'EKS Grundfähigkeitsversicherung',
                link: 'eks-grundfaehigkeitsversicherung'
              },
              {
                name: 'GFV Highlights',
                link: 'gfv-highlights'
              }
            ]
          },
          {
            name: 'Betriebliche Krankenversicherung (BKV)',
            link: 'betriebliche-krankenversicherung'
          },
          {
            name: 'Weitere Absicherungsmöglichkeiten',
            link: 'weitere-absicherungsmoeglichkeiten'
          }
        ]
      },
      {
        name: 'Absicherung für Unternehmen',
        link: 'absicherung-fuer-unternehmen',
        icon: BuildingIcon,
        subLinks: [
          {
            name: 'Keyperson Versicherung',
            link: 'absicherung-ihres-unternehmens#keyperson-versicherung'
          },
          {
            name: 'Betriebsunterbrechungsmöglichkeiten',
            link:
              'absicherung-ihres-unternehmens#betriebsunterbrechungsmoeglichkeiten'
          },
          {
            name: 'Liquidationsversicherung',
            link: 'absicherung-ihres-unternehmens#liquidationsversicherung'
          }
        ]
      }
    ]
  },
  {
    name: 'Dienstleistungen',
    link: 'unsere-dienstleistungen',
    subLinks: [
      {
        name: 'bAV-Beratung und -Betreuung',
        link: 'bav-beratung-und-betreuung',
        icon: HandShakeIcon,
        subLinks: [
          {
            name: 'Die rechtssichere Versorgungsordnung',
            link: '#rechtssichere-versorgungsordnung'
          },
          {
            name: 'Modernisierung/Optimierung von Versorgungswerken',
            link: '#modernisierung-von-versorgungswerken'
          },
          {
            name: 'bAV-Portale',
            link: '#portale'
          }
        ]
      },
      {
        name: 'Versicherungsmathematische Dienstleistungen',
        link: 'versicherungsmathematische-dienstleistungen',
        icon: FileContractIcon,
        subLinks: [
          {
            name: 'Versicherungsmath. Gutachten',
            link: '#versicherungsmath-gutachten'
          },
          {
            name: 'Aktuarielle Dienstleistungen',
            link: '#aktuarielle-dienstleistungen'
          },
          {
            name: 'Auslagerung von Pensionszusagen',
            link: '#auslagerung-von-pensionszusagen'
          }
        ]
      },
      {
        name: 'Personaldienstleistungen',
        link: 'personaldienstleistungen',
        icon: UserCheckIcon,
        subLinks: [
          {
            name: 'Lohn- und Gehaltsabrechnungen',
            link: '#lohn-und-gehaltsabrechnungen'
          },
          {
            name: 'Dienstleistungspaket: Basis',
            link: '#dienstleistungDetailBasis'
          },
          {
            name: 'Dienstleistungspaket: Comfort',
            link: '#dienstleistungDetailComfort'
          },
          {
            name: 'Dienstleistungspaket: Premium',
            link: '#dienstleistungDetailPremium'
          },
          {
            name: 'Zusatzangebote',
            link: '#zusatzangebote'
          }
        ]
      }
    ]
  },
  {
    name: 'Tools & Services',
    link: 'tools-und-services'
  },
  {
    name: 'Mediathek',
    link: 'mediathek'
  },
  {
    name: 'Kontakt',
    link: 'mailto:NBB-Dienstleistungen@nuernberger.de'
  }
];

export default routes;
