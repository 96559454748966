import React from 'react';
import { Link } from 'gatsby';
import { getPathname } from 'src/utils/get-pathname';

const ignoredWords = ['aktuelles'];

const nonReplaceableNames = [
  'bAV-Beratung und -Betreuung',
  'XEMPUS und ePension',
  'Betriebsrenten-Portal'
];

const convertName = name => {
  const replacePatterns = [
    {
      regex: /-/g,
      replaceWith: ' '
    },
    {
      regex: /ae/g,
      replaceWith: 'ä'
    },
    {
      regex: /oe/g,
      replaceWith: 'ö'
    },
    {
      regex: /ue/g,
      replaceWith: 'ü'
    }
  ];

  if (ignoredWords.includes(name)) {
    return name;
  }

  let convertedName = name;

  replacePatterns.forEach(({ regex, replaceWith }) => {
    convertedName = convertedName.replace(regex, replaceWith);
  });

  if (convertedName === 'bav beratung und betreuung') {
    convertedName = 'bAV-Beratung und -Betreuung';
  } else if (convertedName === 'xbav und epension') {
    convertedName = 'XEMPUS und ePension';
  } else if (convertedName === 'betriebsrenten portal') {
    convertedName = 'Betriebsrenten-Portal';
  }

  return convertedName;
};

const createBreadcrumbItems = () => {
  const pathname = getPathname();
  const pathnames = pathname.split('/').slice(1);

  let breadcrumbItems = [];

  pathnames.forEach((pathname, index) => {
    /**
     * Ignore empty pathnames
     */
    if (!pathname) {
      return false;
    }

    if (index === 0) {
      breadcrumbItems.push({ link: pathname, name: convertName(pathname) });
    } else {
      const previousItem = breadcrumbItems[index - 1].link;

      breadcrumbItems.push({
        link: `${previousItem}/${pathname}`,
        name: convertName(pathname)
      });
    }
  });

  return breadcrumbItems;
};

const Separator = () => <span className="mx-1">/</span>;

const Breadcrumb = () => {
  let breadcrumbItems = createBreadcrumbItems();

  // List of breadcrumbs that do not have a corresponding page
  const staticItems = [
    'aktuelles',
    'unsere-loesungen',
    'unsere-dienstleistungen'
  ];

  if (breadcrumbItems.length > 0) {
    return (
      <nav className="link-underline-hovers mb-6 break-words">
        <Link to="/">
          <b>Startseite</b>
        </Link>
        <Separator />
        {breadcrumbItems.map(({ link, name }, index) => (
          <React.Fragment key={index}>
            {staticItems.includes(link) ? (
              <span
                key={index}
                className={`${
                  nonReplaceableNames.includes(name) ? '' : 'capitalize'
                }`}
              >
                {index + 1 < breadcrumbItems.length ? <b>{name}</b> : name}
              </span>
            ) : (
              <Link
                to={`/${link}`}
                className={`${
                  nonReplaceableNames.includes(name) ? '' : 'capitalize'
                }`}
              >
                {index + 1 < breadcrumbItems.length ? <b>{name}</b> : name}
              </Link>
            )}
            {index + 1 < breadcrumbItems.length && <Separator />}
          </React.Fragment>
        ))}
      </nav>
    );
  }

  return null;
};

export default Breadcrumb;
