import React from 'react';
import { Link } from 'gatsby';

import MobileNavigation from 'src/layout/MobileNavigation';
import Navigation from 'src/layout/Navigation';
import Search from 'src/layout/Search';

import Logo from 'src/assets/icons/logo.svg';

const Header = () => (
  <header className="bg-white">
    <div className="container container--simple relative flex justify-end">
      <Link to="/" className="text-dark-blue py-5 mr-auto">
        <Logo
          aria-label="NÜRNBERGER Versicherung Logo"
          className="w-auto h-12 fill-current"
        />
        <span className="sr-only">NBB Website</span>
      </Link>
      <Navigation />
      <Search />
      <MobileNavigation />
    </div>
  </header>
);

export default Header;
