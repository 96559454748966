const metaTags = [
  {
    name: 'description',
    content: 'Placeholder'
  },
  {
    name: 'language',
    content: 'de'
  },
  {
    name: 'robots',
    content: 'index, follow'
  }
];

export default metaTags;
