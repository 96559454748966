import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

import Header from './Header';
import Headvisual from './Headvisual';
import SubpageNavigation from './SubpageNavigation';
import Footer from './Footer';

import metaTags from 'src/data/meta-tags';

const MainLayout = ({
  title,
  children,
  headvisual,
  subpageNavItems,
  activeNavItem,
  useParentPathname
}) => (
  <>
    <Helmet title={`${title} | NBB Website`} meta={metaTags}>
      <html lang="de" />
    </Helmet>
    <Header />
    {headvisual && <Headvisual {...headvisual} />}
    {subpageNavItems && (
      <SubpageNavigation
        navItems={subpageNavItems}
        activeNavItem={activeNavItem}
        useParentPathname={useParentPathname ? true : false}
      />
    )}
    <main>{children}</main>
    <Footer />
  </>
);

MainLayout.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  headvisual: PropTypes.shape({
    image: PropTypes.object.isRequired,
    headline: PropTypes.string.isRequired
  }),
  subpageNavItems: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string.isRequired,
      icon: PropTypes.func,
      name: PropTypes.string.isRequired
    })
  ),
  activeNavItem: PropTypes.number,
  useParentPathname: PropTypes.bool
};

export default MainLayout;
