import React, { useState, useRef } from 'react';
import SearchResult from '../components/SearchResult';
import { debounce } from 'throttle-debounce';

import SearchIcon from '../assets/icons/search.svg';
import CrossIcon from '../assets/icons/cross.svg';

const Search = () => {
  const [searchBarToggle, setSearchBarToggle] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [searchString, setSearchString] = useState('');

  const inputElement = useRef(null);

  const throttledFetch = useRef(
    debounce(250, query => {
      if (query === '') {
        // reset search string
        setSearchString('');

        // reset search results so that search results disappear
        setSearchResults([]);
        return;
      }
      fetch(
        `https://www.googleapis.com/customsearch/v1?cx=008629973093450137565:h1n7b83tgia&key=AIzaSyAqrDrg6EwSQeFemoQl7Dm2D4H3Fd6TYbY&q=${query}`
      )
        .then(response => response.json())
        .then(data => {
          setSearchString(query);
          if (data.items) {
            setSearchResults(data.items);
          }
        });
    })
  ).current;

  const searchSite = event => throttledFetch(event.target.value);

  const handleButtonClick = () => {
    // reset search
    setSearchString('');
    setSearchResults([]);

    // toggle search bar and icon
    setSearchBarToggle(!searchBarToggle);

    // set focus on input
    if (!searchBarToggle === true) {
      setTimeout(() => {
        inputElement.current.focus();
      }, 0);
    }
  };

  const iconClasses = 'fill-current pointer-events-none w-5 h-5 inline mx-4';

  return (
    <>
      <button
        className="flex items-center md:px-2 transition-colors duration-300 hover:bg-dark-blue hover:text-white lg:-mr-4"
        onClick={handleButtonClick}
      >
        {searchBarToggle ? (
          <CrossIcon className={iconClasses} />
        ) : (
          <SearchIcon className={iconClasses} />
        )}
      </button>
      {searchBarToggle && (
        <div className="absolute top-full right-0 left-0 bg-white shadow-xl z-50">
          <div className="m-4">
            <input
              id="searchfield"
              type="search"
              placeholder="Suche..."
              className="form-input w-full"
              ref={inputElement}
              onChange={searchSite}
            />
          </div>
          {searchString !== '' && searchResults.length === 0 && (
            <p className="p-4">Keine Suchergebnisse gefunden.</p>
          )}
          {searchResults.length > 0 &&
            searchResults.map((item, index) => (
              <SearchResult
                key={index}
                name={item.title}
                link={item.link}
                snippet={item.snippet}
              />
            ))}
        </div>
      )}
    </>
  );
};

export default Search;
