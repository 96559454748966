import React from 'react';
import PropTypes from 'prop-types';
import BackgroundImage from 'gatsby-background-image';

import Breadcrumb from 'src/layout/Breadcrumb';

const Headvisual = ({ image, position, headline, paragraph }) => (
  <div className="headvisual">
    <BackgroundImage
      fluid={image.childImageSharp.fluid}
      className={`${position ? `bg-${position}` : 'bg-center'}`}
      preserveStackingContext
    >
      <div className="container container--simple relative z-20 flex items-center md:h-500px">
        <div className={`md:w-1/2 md:pr-4 ${paragraph ? 'py-16' : 'py-24'}`}>
          <Breadcrumb />
          <h1
            className="hyphens-auto"
            dangerouslySetInnerHTML={{ __html: headline }}
          ></h1>
          {paragraph && <p className="mt-4">{paragraph}</p>}
        </div>
      </div>
    </BackgroundImage>
  </div>
);

Headvisual.propTypes = {
  image: PropTypes.object.isRequired,
  position: PropTypes.oneOf([
    'top',
    'bottom',
    'right',
    'left',
    '25',
    '75',
    '85',
    '75-top'
  ]),
  headline: PropTypes.string.isRequired,
  paragraph: PropTypes.string
};

export default Headvisual;
